const pauseAndReset = (audioElem) => {
	if (!audioElem) {
		return;
	}
try {
	audioElem.pause();
}
catch(e) {
	console.log('something went rong in pause',e);
}
	audioElem.currentTime = 0;
};

const playPause = (audioElem, otherAudioElemsObject = {}) => {
	if (audioElem.paused) {
		Object.keys(otherAudioElemsObject).forEach((key) => {
			pauseAndReset(otherAudioElemsObject[key]);
		});

		audioElem.play().catch((e)=>{
			console.log('something went rong in play',e);
		});
	} else {
		pauseAndReset(audioElem);
	}
};

export default playPause;
